div.History{
  width:100%;
  color:white;
}
div.History>div:last-child{
    max-height:calc(40vh - 20px);
    overflow-y:scroll;
    margin-bottom: 20px;
}
div.History>div:last-child::-webkit-scrollbar {
    width: 8px;
}
div.History>div:last-child::-webkit-scrollbar-track {
    background: #f1f1f100; 
    
  }
   
  /* Handle */
  div.History>div:last-child::-webkit-scrollbar-thumb {
    background: #565656; 
    border-radius:4px;
  }
  
  /* Handle on hover */
  div.History>div:last-child::-webkit-scrollbar-thumb:hover {
    background: #555; 
  }
div.historyTitle{
    font-weight:bold;
   
}
div.historyDate{
    font-style: italic;
    font-size:small;
}
div.historyElement{
    margin:20px;
   
}
div.historyMainTitle{
    margin:5px;
    border-bottom: 1px solid rgb(102, 102, 102);
    margin-bottom:10px;
    text-align: center;
}
