div.bodyContainer{
    width:100vw;
    height:100vh;

    display:flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    background-color: rgb(82, 82, 82);
    
}
