div.homeHeader{
    width:100%;;
    height:100%;
    background-color: black;
    display:flex;
    flex-direction:row;
    justify-content: space-between;
}
div.homeHeaderImageContainer{
    width:100%;
 
    height:calc(100vw * 0.4);
    
}
img.headerImage{
    width:100%;
    height:100%;
    object-fit: cover;
    object-position: center;
    transition: opacity 0.5s;
    position:absolute;
    top:0px;

}
div.homeHeaderLogo{
    width:100%;
    background-color: rgba(0,0,0,0.5);
    height:calc(100vw * 0.4);
    max-height:300px;
    position:absolute;
    top:0px;
   
    display:flex;
    flex-direction: row;
}
#logoWhite{
    height:calc(100vw * 0.4);
    max-height:300px;
}
.headerTitle{
    width:100%;
    height:calc((100vw * .4)*.8677);
    max-height:300px;
    border-top:calc((100vw * .4)*.0746) solid white;
    border-bottom:calc((100vw * .4)*.06) solid white;
    display:flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    color:white;
    opacity:1;
}
@media (min-width:750px){
    .headerTitle{
        width:100%;
        height:262px;
        border-top:22px solid white;
        border-bottom:16px solid white;
        display:flex;
        flex-direction: column;
        align-items: center;
        justify-content: center;
        color:white;
        opacity:1;
    }
    img.headerImage{
        object-position: top;
    }
    img#title0{
        object-position:bottom;
    }
  
}
.hidden{
    opacity:0;
}
div.headerTitle>div:first-child{
    font-family: Georgia, 'Times New Roman', Times, serif;
    font-size:calc(100vw * 0.1);
}
div.headerTitle>div:last-child{
    font-family:'Trebuchet MS', 'Lucida Sans Unicode', 'Lucida Grande', 'Lucida Sans', Arial, sans-serif;
    font-size:calc(100vw * 0.02);
    
}
@media (min-width:1200px){
    div.headerTitle>div:first-child{
        font-family: Georgia, 'Times New Roman', Times, serif;
        font-size:120px;
    }
    div.headerTitle>div:last-child{
        font-family:'Trebuchet MS', 'Lucida Sans Unicode', 'Lucida Grande', 'Lucida Sans', Arial, sans-serif;
        font-size:24px;
        
    }
}
