div.navBar{
    position:absolute;
    bottom:0;
    width:100vw;
    color:white;
    display:flex;
    flex-direction:row;
    justify-content: space-between;
    align-items:end;
    z-index:100;
    background-color: rgba(0, 0, 0, 0.566);
}
div.navBar>div:last-child{
    display:flex;
    flex-direction:column;
    justify-content: space-evenly;
    align-items:end;
}
a{
    color:rgb(255, 255, 255);
    margin:10px;
    display:flex;
    flex-direction: column;
    align-items: end;
    
}
a:hover{
    font-weight:bold;
}
.active{
    font-weight:bold;
}
a>p{
    margin-top:0px;
    margin-bottom: 0px;
    font-size: small;
    font-family: 'Courier New', Courier, monospace;
    text-align: left;
    width:100%;
}

